import React, { useState, useContext, useEffect } from 'react'
import { useLocation } from '@reach/router';
import Loader from "react-loader-spinner";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import ReCAPTCHA from "react-google-recaptcha";

import { FinanceCalculatorContext } from '../financing/storage/CalculatorContext';
import useRenderizable from '../hooks/useVehicleContextValidator';
import useDataLayer from '../../../hooks/useDataLayer';
import mfc from '../modules/modalFormClient';

import "../../contact/ContactForm.scss"
import grid from "../../../scss/flexboxgrid.module.scss"
import view from "./form.module.scss"

import {localStorage, getStorageItem, setStorageItem, removeStorageItem} from '../../localStorage/localStorage.js'
import { captureEvent } from 'event-service/modules/capture';

function AvailabilityForm(props) {
  const [context] = useContext(FinanceCalculatorContext)
  const { finalPrice, vehicleInfo } = props.contextualized ? context : props
  const { site_id, leadURL, disclaimerContact, gtmId, useFrench } = props

  const selectedDealer = getStorageItem('selectedDealer')
  const closestDealers = localStorage.getItem("localDistanceData"+site_id) != 'null' ? JSON.parse(localStorage.getItem("localDistanceData"+site_id)): []
  const landedDealerId = getStorageItem('masteratiDealerId')
  const dealers = (closestDealers != null && closestDealers.hasOwnProperty("closest_dealers") && closestDealers.closest_dealers.length > 0) ? closestDealers.closest_dealers : props.dealers.dealerRef.sort((a, b) => {
    var textA = a.dealerName.toUpperCase();
    var textB = b.dealerName.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }); 
  
  const landingDealerName = dealers.find(dealer => dealer.dealerId == landedDealerId)?.dealerName

  if (landedDealerId)
    {
      setStorageItem('selectedDealer', landedDealerId)
    }

  const pushToDataLayer = useDataLayer()

  const requiredFields = ["VIN", "StockNumber", "Year", "Make", "Model", "Trim"]
  const renderizable = useRenderizable({ finalPrice, vehicleInfo }, requiredFields)
  const isRenderizable = props.contextualized ? renderizable : true
  const [modelOptions, setModelOptions] = useState([])
  const [defaultModelOption, setDefaultModelOption] = useState(null)
  const [selectedModelOption, setSelectedModelOption] = useState(null);
  const recaptchaRef = React.createRef();
  const filterLabels = () => Object.fromEntries(
    requiredFields.map(element => [element, vehicleInfo[element]])
  )

  const [fields, setFields] = useState({
    firstName: null, lastName: null, email: null, phoneNumber: null, comments: null, dealerName: getStorageItem('selectedDealer'),
    location: vehicleInfo?.DealerName, siteId: site_id, pageSource: props.pageSource, pageURL: props.pageURL, preferredContact: "Email"
  })
  
  
  const location = useLocation()
  useEffect(() => {
    if(props.autofillable){
      const userForm = {}
      const params = new URLSearchParams(location.search)
      for (const [key, value] of params) {
        fields.hasOwnProperty(key) && (userForm[key] = value);
      }
      setFields({ ...fields, ...userForm})
    } 
  }, [])
  
  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    let modelOption = `${queryParams.get("model") ? queryParams.get("model") : "" } ${queryParams.get("trim") ? queryParams.get("trim") : ""}`.trim();
    let foundOption = false;
  
    console.log("Parsed model option:", modelOption);
  
    const matchedOption = initialOptions.find(
      (option) => option.value.toLowerCase() === modelOption.toLowerCase()
    );
  
    if (matchedOption) {
      setModelOptions(initialOptions);
      setSelectedModelOption([matchedOption]);
      foundOption = true;
    }
    else{
      let newOption = {value: modelOption, label: modelOption};
      initialOptions.unshift(newOption);
      setModelOptions(initialOptions);
      setSelectedModelOption([newOption]);
    }
  
    console.log("Found option:", foundOption);
    console.log("Default model option:", matchedOption);
  }, []);
  

  const [error, setError] = useState([])
  const [statusMsg, setStatusMsg] = useState("")
  const [loading, isLoading] = useState(false)
  const [success, setSuccess] = useState(false )
  const [engagement, setEngagement] = useState(false)
  const [recaptchaValid, setRecaptchaValid] = useState(false)

  
  useEffect(() => {
    if (engagement) {
      captureEvent({
        event: 'asc_form_engagement',
        comm_type: 'form',
        comm_status: 'start',
        element_text: engagement?.innerText,
        department: 'sales',
        form_name: 'Check Availability',
        form_type: 'sales',
        item_id:  vehicleInfo.VIN,
        item_number: `${vehicleInfo.Year} ${vehicleInfo.Make} ${vehicleInfo.Model} ${vehicleInfo.Trim}`,
        item_price: `${finalPrice}`,
        item_condition: `${vehicleInfo.IsNew ? 'New' : 'Used'}`,
        item_year: vehicleInfo.Year,
        item_make: vehicleInfo.Make,
        item_model: vehicleInfo.Model,
        item_variant: vehicleInfo.Trim,
        item_color: vehicleInfo.ExteriorColor,
        item_type:'vehicle',
        item_category: vehicleInfo.BodyType,
        item_fuel_type: vehicleInfo.EngineFuelType,
        item_inventory_date: vehicleInfo.InStockDate,
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
    }
  }, [engagement])

  const handleTextInput = (name, value) => {
    const isErrored = error.indexOf(name)
    if(isErrored > -1){
      const _error = [...error]
      _error.splice(isErrored, 1)
      setError(_error)
    }

    setStatusMsg("")
    setFields({ ...fields, ...{ [name]: value } })
  }

  const phoneMask = (e) => {
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
  }
  
  const checkValidPhone = (phone) => {
    // Remove all non-numeric characters
    const cleanedPhone = phone.replace(/\D/g, '');
    
    // Check if the cleaned phone number matches the desired format
    return cleanedPhone.match(/^[0-9]{10}$/);
  };
  
  const checkValidEmail = (email) => { 
    return email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  }

  const submitForm = async (e) => {
    e.preventDefault()
    
    if (!selectedModelOption || selectedModelOption.length === 0) {
      setError([...error, "model"])
      return setStatusMsg(!useFrench ? "Please select at least one model!" : "Veuillez sélectionner au moins un modèle !")
    }
    
    let allModelsSelected = selectedModelOption.map((option) => option.value).join(", ");
    allModelsSelected = "All Selected Models: " + allModelsSelected;
    //Get vehicle info
    let vehicleParams = {}
    let queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has("model")){
      vehicleParams["model"] = queryParams.get("model")
    }
    if (queryParams.has("make")){
      vehicleParams["make"] = queryParams.get("make")
    }
    if (queryParams.has("year")){
      vehicleParams["year"] = queryParams.get("year")
    }
    if (queryParams.has("trim")){
      vehicleParams["trim"] = queryParams.get("trim")
    }
    
    const missing = mfc.validateForm(fields, ['firstName', 'lastName', 'email', 'phoneNumber'])
    if (missing.length > 0) {
      setError([...missing])
      return setStatusMsg(!useFrench ? "One or more fields are missing!" : " Il manque un ou plusieurs champs !")
    }
    
    //Check Phone Number
    if (!checkValidPhone(fields.phoneNumber)) { 
      setError([...error, "phoneNumber"])
      return setStatusMsg(!useFrench ? "Invalid phone number!" : "Numéro de téléphone invalide !")
    }
    
    //Check Email
    if (!checkValidEmail(fields.email)) { 
      setError([...error, "email"])
      return setStatusMsg(!useFrench ? "Invalid email address!" : "Adresse e-mail invalde !")
    }
    
    if (!recaptchaValid) {
      return setStatusMsg(!useFrench ? "Please complete the reCAPTCHA!" : "Veuillez compléter le reCAPTCHA !")
    }
    
    //Get Preferred Contact Method
    //Add all models selected to comments
    fields.comments = allModelsSelected;

    isLoading(true)

    const body = { ...fields, vehicle: filterLabels(), vehicleParams: vehicleParams, recaptchaToken: recaptchaRef.current.getValue() }
    const result = await mfc.sendForm(leadURL, body)
    if (result.hasOwnProperty("error")) {
      setStatusMsg(!useFrench ? "Uh oh! Something went wrong, please try again!" : "Glups !  Il y a eu un problème, veuillez réessayer !")
    } else {
      pushToDataLayer("form-submitted", result)
      pushToDataLayer("contact", result)
      setStatusMsg(!useFrench ? "Success! The message has been sent!" : "Le message a bien été envoyé ! ")
      setSuccess(true)
      captureEvent({
        event: 'asc_form_submission',
        comm_type: 'form',
        comm_outcome: 'crm_update',
        submission_id: `${result.result.data.LeadID.id}`,
        element_text: 'submit',
        department: 'sales',
        form_name: 'Availability Form',
        form_type: 'sales',
        item_id:  vehicleInfo.VIN,
        item_number: `${vehicleInfo.Year} ${vehicleInfo.Make} ${vehicleInfo.Model} ${vehicleInfo.Trim}`,
        item_price: `${finalPrice}`,
        item_condition: `${vehicleInfo.IsNew ? 'New' : 'Used'}`,
        item_year: vehicleInfo.Year,
        item_make: vehicleInfo.Make,
        item_model: vehicleInfo.Model,
        item_variant: vehicleInfo.Trim,
        item_color: vehicleInfo.ExteriorColor,
        item_type:'vehicle',
        item_category: vehicleInfo.BodyType,
        item_fuel_type: vehicleInfo.FuelType,
        item_inventory_date: vehicleInfo.InStockDate,
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
    }
    
    isLoading(false)
  }

  const updateDealerDropdown = (value) => {
    setStorageItem('selectedDealer', value)
    fields.dealerName = value;
  }
  
  const updatePreferredContact = (value) => {
    fields.preferredContact = value;
  }
  
  const initialOptions = [
    {"value": "Grecale Folgore", "label": "Grecale Folgore"},
    {"value": "Grecale GT", "label": "Grecale GT"},
    {"value": "Grecale Modena", "label": "Grecale Modena"},
    {"value": "Grecale Trofeo", "label": "Grecale Trofeo"},
    {"value": "Levante GT Ultima", "label": "Levante GT Ultima"},
    {"value": "Levante Modena Ultima", "label": "Levante Modena Ultima"},
    {"value": "Levante V8 Ultima", "label": "Levante V8 Ultima"},
  ]
  
  const handleOptionChange = (selectedOption) => {
    setSelectedModelOption(selectedOption);
  };
  
  return (
    isRenderizable &&  (

      <div className={`contact-formCont ${view["contact-form"]}`} onClick={() => setEngagement({innerText: "Dealer"})}>

        <div className={`contact-inputCont ${grid["col-sm-12"]} select`}>
          <label htmlFor="dealerName">{!useFrench ? "Dealer" : "Concessionnaire" }</label>
          <select id="dealerName" name="dealerName" onChange={(e) => {updateDealerDropdown(e.target.value);}}>
              {
                landedDealerId ? 
                <option value={landedDealerId} selected={true}>{landingDealerName}</option> :
                dealers.map((element, i) => { 
                  let distance = element.hasOwnProperty('distances') ? ' (' + element.distances.distance.text + ')' : '';
                  return(
                  <option key={i} value={element.dealerId} selected={element.dealerName === selectedDealer}>{element.dealerName + distance}</option>
                  )
                })
              }
          </select>
        </div>
        <label htmlFor="interestedModels">{!useFrench ? "Models of Interest" : "Modèles d'intérêt" }</label>
        <div className={`contact-inputCont ${grid["col-sm-12"]} select`}>
          <Select id="interestedModels" value={selectedModelOption} onChange={handleOptionChange} closeMenuOnSelect={ false } isMulti options={modelOptions} />
        </div>
        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "First Name"})} >
          <label htmlFor="firstName">{!useFrench ? "First Name"  : "Prénom"} *</label>
          <input className="contact-input" id="firstName" name="firstName"
            value={fields.firstName} placeholder=""
            style={{ border: error.indexOf("firstName") > -1 && "1px solid red"}}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>

        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "Last Name"})}>
          <label htmlFor="lastName">{!useFrench ? "Last Name" : "Nom"} *</label>
          <input className="contact-input" id="lastName" name="lastName" 
            value={fields.lastName} placeholder=""
            style={{ border: error.indexOf("lastName") > -1 && "1px solid red"}}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>

        <div className={`contact-inputCont contact-phone ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "Phone"})}>
          <label htmlFor="phoneNumber">{!useFrench ? "Phone" : "Téléphone"} *</label>
          <input className="contact-input" type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
            id="phoneNumber" name="phoneNumber" 
            value={fields.phoneNumber} placeholder="(555) 555-5555"
            style={{ border: error.indexOf("phoneNumber") > -1 && "1px solid red"}}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)}
            onInput={phoneMask}
          />
        </div>

        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "Email"})}>
          <label htmlFor="email">{!useFrench ? "Email" : "E-Mail"} *</label>
          <input className="contact-input" type="email" id="email" name="email"
            value={fields.email} placeholder=""
            style={{ border: error.indexOf("email") > -1 && "1px solid red"}}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>
        
        <div className={`contact-inputCont ${grid["col-sm-6"]} select`} onClick={() => setEngagement({innerText: "Preferred Contact Method"})}>
          <label htmlFor="preferredContact">{!useFrench ? "Preferred Contact Method" : "Mode de contact préférée"}</label>
          <select id="preferredContact" name="preferredContact" onChange={(e) => {updatePreferredContact(e.target.value);}}>
            <option key={"Email"} value={"Email"} selected={true}>{!useFrench ? "Email" : "E-Mail"}</option>
            <option key={"Text"} value={"Text"} >{!useFrench ? "Text Message" : "Message de test"}</option>
            <option key={"Phone"} value={"Phone"} >{!useFrench ? "Phone Call" : "Appel téléphonique"}</option>
          </select>
        </div>

        {/* <div className="contact-inputCont contact-comment" onClick={() => setEngagement({innerText: "Comment"})}>
          <label htmlFor="comments">Comment</label>
          <textarea className="contact-input" id="comments" name="comments"
            value={fields.comments} placeholder=""
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div> */}

        <div className="contact-inputCont contact-border">
          <div className="contact-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimerContact }}></div>
          {/* <button className="contact-button" onClick={submitForm}>
            {loading === false ? "SUBMIT" :
              <Loader type="ThreeDots" color="white" height={12} width={12} />
            }
          </button> */}
          <div >
            <ReCAPTCHA
              sitekey="6Lc8-LIqAAAAANhu7PkKJla89rvdnAe8plktPKMw"
              size="normal"
              hl={useFrench ? "fr" : "en"}
              onChange={() => setRecaptchaValid(true)}
              onExpired={() => setRecaptchaValid(false)}
              onErrored={() => setRecaptchaValid(false)}
              ref={recaptchaRef}
            ></ReCAPTCHA>
          </div>
          {
            !success && !loading ? 
            <button className="contact-button"  onClick={submitForm}>
              {!useFrench ? "SUBMIT" : "SOUMETTRE"}
            </button>  :
            <div className={success ? "circle-loader-contact load-complete-contact" : "circle-loader-contact"}>
              <div className={success ? "checkmark-contact draw" : ""}></div>
            </div>
          }
        </div>

        <div className={`contact-response ${statusMsg.length && "contact-failure"}`}>
          {statusMsg}
        </div>
      </div>
    )
  )
}

AvailabilityForm.defaultProps = {
  leadURL: "",
  disclaimerContact: "<p></p>",
  finalPrice: 0,
  vehicleInfo: {}
}

export default AvailabilityForm
