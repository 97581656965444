import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import view from "./cookie.module.scss"

export default function CookiePolicy(props) {
  const { dealerInfo, CookieLinkText, useFrench } = props
  const disclaimer = props.disclaimer

  const localStorage = typeof window !== 'undefined' && window.localStorage
  const privModal = getStorageItem('CookiePolicyShown');

  const [modalVisible, toggleModal] = useState(false)

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : false)
  }

  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }

  const closeModal = () => {
    toggleModal(false)
    setStorageItem('CookiePolicyShown', true);
  }

  const openModel = () => {
    toggleModal(true)
  }

  useEffect(() => {
    if (privModal === null) {
      toggleModal(true);
    }
  });
  return (
    <>{
      modalVisible ? (
        <div className={`${view["cookie-modal"]}`}>
          <div className={`${view["cookieFlex"]}`}>
            {
              disclaimer ?
                (
                  <>
                    <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
                    <span className={`${view["close-modal-link"]} close-modal`} onClick={closeModal}>
                      {`  Close  `} 
                    </span>
                  </>
                ) :
                (
                  <p>
                    {!useFrench ? "By continuing to use our site, you agree to our " : "En continuant d'utiliser notre site, vous acceptez "}
                    <Link target="_blank" to={getStorageItem("overrideTOS") ? getStorageItem("overrideTOS") : dealerInfo.termsOfService} className={`${view["modal-links"]}`}>{!useFrench ? "Terms of Service " : "nos conditions d'utilisation "}</Link> {!useFrench ? "and": "et"}
                    <Link target="_blank" to={getStorageItem("overridePrivacy") ? getStorageItem("overridePrivacy") : dealerInfo.privacy} className={`${view["modal-links"]}`}> {!useFrench ? "Privacy Policy " : "notre politique de confidentialité "}</Link>.
                    {!useFrench ? "You can learn more about how we use cookies by reviewing our " : "Pour en savoir plus sur la manière dont nous utilisons les cookies, vous pouvez consulter "}
                    <Link target="_blank" to={getStorageItem("overridePrivacy") ? getStorageItem("overridePrivacy") : dealerInfo.privacy}
                      className={`${view["modal-links"]}`}>{!useFrench ? "Privacy Polic " : "notre politique de confidentialité "}</Link>.
                    <span className={`${view["close-modal-link"]} close-modal`} onClick={closeModal}> {!useFrench ? `  Close  ` : " Fermer "} </span>
                  </p>
                )
            }
          </div>
        </div>
      ) : (<></>)
    }
      <div className={` ${view["cookie-policy"]} subtitle`} >
        <span onClick={openModel}> {CookieLinkText}</span>
      </div>
    </>
  )
}

CookiePolicy.defaultProps = {
  visible: true,
  closeModal: () => { }
}
